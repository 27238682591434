module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SoixanteSeize","short_name":"SoixanteSeize","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","icon":"src/img/favicon.ico","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4b21e028f14b9d25b588575c61858ee4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["HelveticaNeueLTPro-Th","HelveticaNeueLTPro-UltLt","HelveticaNeueLTPro-Lt","HelveticaNeueLTPro-Bd","HelveticaNeueLTPro-LtIt","HelveticaNeueLTPro-BdIt","HelveticaNeueLTPro-Roman"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["4753028306","G-NZC42B8C65"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"113330941709831"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
